<template>
  <div ref="renderArea" class="relative w-full h-full"
  @mousemove="handleGraphMouseOver" @mouseleave="hoveredDataPoint = null">
    <!-- Background Plot Lines -->
    <div class="absolute top-0 left-0 w-full h-full">
      <BackgroundRenderer :dimensions="renderAreaDimensions" :num-plot-lines="numPlotLines" />
    </div>
    <!-- x-positioners -->
    <div class="w-full h-full flex items-center justify-between" style="padding-left: 18px"
    :style="{ paddingRight: usesFullDateInterval ? '18px' : '66px' }">
      <div v-for="(_, index) in dateSegments" ref="xPositioners" :key="`date-segment-${index}`" 
      class="relative w-px h-full">
        <div 
          v-for="pointMarker in graphDataPointMarkers?.[index]?.dataPoints || []"
          v-show="pointMarker.seriesId === hoveredDataPoint?.seriesId && hoveredDataPoint?.datePosition === index"
          :key="`point-marker-${index}-${pointMarker.seriesId}`"
          class="data-point-marker" 
          style="z-index: 15"
          :style="{ 
            top: `${pointMarker.yPos}px`,
            backgroundColor: lineColorMap.get(pointMarker.seriesId),
          }"
        />
      </div>
    </div>
    <!-- Primary Metric Lines -->
    <transition-group name="line">
      <DynamicLine
        v-for="(dataItem, index) in graphData"
        :key="`primary-line-${dataItem?.group_by || dataItem?.ad_id}`"
        class="dynamic-line primary"
        :class="{ 'inactive': hoveredDataPoint && hoveredDataPoint?.seriesId !== (dataItem?.group_by || dataItem?.ad_id) }"
        :style="{ zIndex: hoveredDataPoint?.seriesId === (dataItem?.group_by || dataItem?.ad_id) ? 50 : graphData.length - index }"
        :dimensions="renderAreaDimensions" 
        :coordinates="primaryMetricCoordinatesMap?.get(dataItem?.group_by || dataItem?.ad_id) || []"
        :data-index="index"
        is-primary-metric
        @apply-marker-offsets="applyMarkerOffsets($event, dataItem?.group_by || dataItem?.ad_id)"
      />
    </transition-group>
    <!-- Secondary Metric Lines -->
    <transition-group name="fade">
      <DynamicLine
        v-for="(dataItem, index) in graphData"
        v-show="hoveredDataPoint?.seriesId === (dataItem?.group_by || dataItem?.ad_id)"
        :key="`secondary-line-${dataItem?.group_by || dataItem?.ad_id}`"
        class="dynamic-line"
        :style="{ zIndex: graphData.length - index }"
        :dimensions="renderAreaDimensions" 
        :coordinates="secondaryMetricCoordinatesMap?.get(dataItem?.group_by || dataItem?.ad_id) || []"
        :data-index="index"
        :is-primary-metric="false"
      />
    </transition-group>
    <!-- Animating Hover Tooltip -->
    <div v-show="tooltipData" class="hover-tooltip flex flex-col gap-1 p-1 rounded-lg pointer-events-none"
    :style="{ transform: tooltipTransform }">
      <!-- Identifier and Date -->
      <div class="flex flex-col gap-0.5 p-1.5" ref="tooltipHeader" style="min-height: 50px">
        <BaseText type="body" size="sm" class="text-white group-tooltip-name">
          {{ tooltipData?.name }}
        </BaseText>
        <BaseText type="body" size="xs" class="text-neutral-alpha-650">
          {{ tooltipData?.date }}
        </BaseText>
      </div>
      <!-- Metrics -->
      <div class="w-full flex flex-col gap-1 p-2 rounded bg-neutral-alpha-50 mt-auto">
        <!-- Primary Metric -->
        <div class="w-full flex items-center min-w-0">
          <PrimaryMetricLineIcon class="text-neutral-alpha-650 flex-shrink-0" />
          <BaseText type="body" size="sm" class="flex-grow truncate text-neutral-alpha-650 mr-1">
            {{ getMetricLookup?.[selectedKpis[0]]?.name || selectedKpis[0] }}
          </BaseText>
          <BaseText type="label" size="sm" class="text-white">
            {{ tooltipData?.primaryValue }}
          </BaseText>
          <BaseText v-if="tooltipData?.primaryValueChange" type="body" size="sm" class="ml-2"
          :class="`text-${tooltipData?.primaryChangeColor}`">
            {{ tooltipData?.primaryValueChange }}
          </BaseText>
        </div>
        <!-- Secondary Metric -->
        <div v-if="tooltipData?.secondaryValue" class="w-full flex items-center min-w-0">
          <SecondaryMetricLineIcon class="text-neutral-alpha-650 flex-shrink-0" />
          <BaseText type="body" size="sm" class="flex-grow truncate text-neutral-alpha-650 mr-1">
            {{ getMetricLookup?.[selectedKpis[1]]?.name || selectedKpis[1] }}
          </BaseText>
          <BaseText type="label" size="sm" class="text-white">
            {{ tooltipData?.secondaryValue }}
          </BaseText>
          <BaseText v-if="tooltipData?.secondaryValueChange" type="body" size="sm" class="ml-2"
          :class="`text-${tooltipData?.secondaryChangeColor}`">
            {{ tooltipData?.secondaryValueChange }}
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import lineColors from '../../../../utils/lens/selectedMetricColors'
import formatLabelString from '../../../../utils/lens/formatGraphLabelString'
import smoothReflow from 'vue-smooth-reflow'

// Components
import DynamicLine from './DynamicLine.vue'
import BackgroundRenderer from './BackgroundRenderer.vue'

// Icons
import PrimaryMetricLineIcon from '../../../globals/Icons/LensIcons/graphs/PrimaryMetricLineIcon.vue'
import SecondaryMetricLineIcon from '../../../globals/Icons/LensIcons/graphs/SecondaryMetricLineIcon.vue'

const DATA_POINT_HOVER_Y_THRESHOLD = 50 // px

export default {
  name: 'LineGraphRenderer',
  mixins: [smoothReflow],
  components: {
    DynamicLine,
    BackgroundRenderer,
    PrimaryMetricLineIcon,
    SecondaryMetricLineIcon
  },
  props: {
    graphData: {
      type: Array,
      default: () => []
    },
    selectedKpis: {
      type: Array,
      default: () => []
    },
    axisScales: {
      type: Array,
      default: () => []
    },
    dateSegments: {
      type: Array,
      default: () => []
    },
    rawDateSegments: {
      type: Array,
      default: () => []
    },
    usesFullDateInterval: {
      type: Boolean,
      default: true
    },
    dateIntervalDays: {
      type: Number,
      default: 0
    },
    numPlotLines: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      primaryMetricCoordinatesMap: null,
      secondaryMetricCoordinatesMap: null,
      renderAreaDimensions: { width: 0, height: 0 },
      resizeObserver: null,

      graphDataPointMarkers: [],
      hoveredDataPoint: null,
      tooltipData: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$refs.tooltipHeader) {
        this.$smoothReflow({
          el: this.$refs.tooltipHeader,
          property: 'height',
          transition: 'height 200ms ease-in-out'
        })
      }
      if (this.$refs.renderArea) {
        this.resizeObserver = new ResizeObserver(() => {
          this.computeItemCoordinates()
        })
        this.resizeObserver.observe(this.$refs.renderArea)
      }
    })
  },
  beforeDestroy () {
    if (this.resizeObserver && this.$refs.renderArea) {
      this.resizeObserver.unobserve(this.$refs.renderArea)
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
    if (this.$refs.tooltipHeader) {
      this.$unsmoothReflow({ el: this.$refs.tooltipHeader })
    }
  },
  watch: {
    graphData: {
      handler () {
        this.$nextTick(() => { this.computeItemCoordinates() })
      },
      deep: true,
      immediate: true
    },
    hoveredDataPoint (newVal) {
      this.updateTooltipData(newVal)
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    lineColorMap () {
      const colorMap = new Map()
      this.graphData.forEach((dataItem, index) => {
        colorMap.set(dataItem?.group_by || dataItem?.ad_id, lineColors[index])
      })
      return colorMap
    },
    lineRenderPriorityMap () {
      const priorityMap = new Map()
      this.graphData.forEach((dataItem, index) => {
        priorityMap.set(dataItem?.group_by || dataItem?.ad_id, this.graphData.length - index)
      })
      return priorityMap
    },
    xPositions () {
      return this.graphDataPointMarkers.map(pointMarker => pointMarker.xPos)
    },
    tooltipTransform () {
      if (!this.tooltipData) return 'translate(0, -100%)'
      const { xTransform, yTransform } = this.tooltipData
      const xStr = `${xTransform}px`
      const yStr = `calc(-100% + ${yTransform}px)`
      return `translate(${xStr}, ${yStr})`
    }
  },
  methods: {
    computeItemCoordinates () {
      const primaryCoordinatesMap = new Map()
      const secondaryCoordinatesMap = new Map()
      const renderArea = this.$refs.renderArea
      const xPositioners = this.$refs.xPositioners
      if (!renderArea || !xPositioners?.length > 1 || !this.selectedKpis.length || !this.axisScales?.length) return

      // Update the render area dimensions
      this.renderAreaDimensions = {
        width: renderArea.getBoundingClientRect().width,
        height: renderArea.getBoundingClientRect().height
      }
      // Update the date segment length
      const dateSegmentLengthPx = xPositioners[1].getBoundingClientRect().left - xPositioners[0].getBoundingClientRect().left

      // Set up an array for storing data point marker information (based on the primary metric - date first organization)
      const dataPointMarkers = this.dateSegments.map(date => ({ date, xPos: 0, dataPoints: [] }))

      // Computations for determining coordinates within the render area and defining the data point markers
      const renderAreaRect = renderArea.getBoundingClientRect()
      const availableHeight = renderAreaRect.height
      const computeCoords = (xIndex, value, seriesId, isPrimaryMetric = true) => {
        // Compute coordinate values
        const xPos = xPositioners[xIndex].getBoundingClientRect().left - renderAreaRect.left
        const scale = this.axisScales[isPrimaryMetric ? 0 : 1]
        scale.range([0, availableHeight])
        const yPos = availableHeight - scale(value)

        // Update the data point marker information
        if (isPrimaryMetric) {
          const dataPointMarker = dataPointMarkers[xIndex]
          if (!dataPointMarker.xPos) dataPointMarker.xPos = xPos

          // Insert the data point marker into its sorted position
          let insertIndex = 0
          const dataPoints = dataPointMarker.dataPoints
          while (insertIndex < dataPoints.length && dataPoints[insertIndex].yPos < yPos) {
            insertIndex++
          }
          dataPoints.splice(insertIndex, 0, { seriesId, value, yPos })
        }

        return { x: xPos, y: yPos }
      }

      // For each time series group, compute the coordinates for the primary and secondary metrics
      for (const timeSeriesGroup of this.graphData) {
        const seriesId = timeSeriesGroup.group_by || timeSeriesGroup.ad_id
        const primaryCoords = []
        const secondaryCoords = []
        let xIndex = 0
        // Compute the coordinates for each event in the time series data
        for (const eventData of timeSeriesGroup.data) {
          while (xIndex < this.rawDateSegments.length && eventData.event_date !== this.rawDateSegments[xIndex]) {
            xIndex++
          }
          if (xIndex >= this.rawDateSegments.length) {
            console.log('Didnt find event date in rawDateSegments', eventData.event_date, this.rawDateSegments)
            break
          }
          primaryCoords.push(
            { ...computeCoords(xIndex, eventData[this.selectedKpis[0]], seriesId, true), dateIndex: xIndex }
          )
          if (this.selectedKpis.length > 1) {
            secondaryCoords.push(computeCoords(xIndex, eventData[this.selectedKpis[1]], seriesId, false))
          }
        }

        // === Compute Boundary Points ===
        // -- Left Side --
        const boundaries = timeSeriesGroup.boundaries
        // Compute the left-side boundary point for the primary metric line
        if (boundaries?.prev && primaryCoords.length) {
          const B = { x: primaryCoords[0].x, y: primaryCoords[0].y }
          const primaryLeftBoundaryCoord = this.computeBoundaryCoordinates(boundaries.prev, B, 'left', dateSegmentLengthPx, true)
          if (primaryLeftBoundaryCoord) primaryCoords.unshift(primaryLeftBoundaryCoord)
        }
        // Compute the left-side boundary point for the secondary metric line
        if (this.selectedKpis.length > 1 && boundaries?.prev && secondaryCoords.length) {
          const B = { x: secondaryCoords[0].x, y: secondaryCoords[0].y }
          const secondaryLeftBoundaryCoord = this.computeBoundaryCoordinates(boundaries.prev, B, 'left', dateSegmentLengthPx, false)
          if (secondaryLeftBoundaryCoord) secondaryCoords.unshift(secondaryLeftBoundaryCoord)
        }
        // -- Right Side --
        // Compute the right-side boundary point for the primary metric line
        if (boundaries?.next && primaryCoords.length) {
          const B = { x: primaryCoords[primaryCoords.length - 1].x, y: primaryCoords[primaryCoords.length - 1].y }
          const primaryRightBoundaryCoord = this.computeBoundaryCoordinates(boundaries.next, B, 'right', dateSegmentLengthPx, true)
          if (primaryRightBoundaryCoord) primaryCoords.push(primaryRightBoundaryCoord)
        }
        // Compute the right-side boundary point for the secondary metric line
        if (this.selectedKpis.length > 1 && boundaries?.next && secondaryCoords.length) {
          const B = { x: secondaryCoords[secondaryCoords.length - 1].x, y: secondaryCoords[secondaryCoords.length - 1].y }
          const secondaryRightBoundaryCoord = this.computeBoundaryCoordinates(boundaries.next, B, 'right', dateSegmentLengthPx, false)
          if (secondaryRightBoundaryCoord) secondaryCoords.push(secondaryRightBoundaryCoord)
        }
      // =========================

        primaryCoordinatesMap.set(seriesId, primaryCoords)
        if (this.selectedKpis.length > 1) secondaryCoordinatesMap.set(seriesId, secondaryCoords)
      }

      // Set the computed coordinates
      this.primaryMetricCoordinatesMap = primaryCoordinatesMap.size ? primaryCoordinatesMap : null
      this.secondaryMetricCoordinatesMap = secondaryCoordinatesMap.size ? secondaryCoordinatesMap : null

      // Set the data point markers
      this.graphDataPointMarkers = dataPointMarkers
    },
    computeBoundaryCoordinates (boundaryData, B, direction, segLengthPx, isPrimaryMetric) {
      if (
        !this.dateSegments?.length || !segLengthPx || !this.dateIntervalDays 
        || !this.axisScales?.length || !this.renderAreaDimensions?.height || !this.renderAreaDimensions?.width
        || !this.selectedKpis?.length || (!isPrimaryMetric && !this.selectedKpis?.length > 1)
      ) {
        return null
      }

      // Compute necessary constants
      const i_px = segLengthPx
      const i_days = this.dateIntervalDays
      const d_days = Math.ceil(Math.abs(this.dateSegments[0] - convertDateString(boundaryData.event_date)) / (1000 * 60 * 60 * 24))

      // Compute the point A (the imaginary point positioned outside the graph area)
      const boundaryValue = boundaryData?.[isPrimaryMetric ? this.selectedKpis[0] : this.selectedKpis[1]]
      if (boundaryValue === undefined || boundaryValue === null) {
        console.error("Couldn't get boundary value for series", boundaryData, direction, isPrimaryMetric)
        return null
      }
      const availableHeight = this.renderAreaDimensions.height
      const scale = this.axisScales[isPrimaryMetric ? 0 : 1]
      scale.range([0, availableHeight])
      const A_x = B.x + (direction === 'left' ? -1 : 1) * (i_px * (d_days / i_days))
      const A_y = availableHeight - scale(boundaryValue)
      const A = { x: A_x, y: A_y }
      
      // Compute the necessary vectors and their dot product/norms
      const v1 = { x: A.x - B.x, y: A.y - B.y }
      const v2 = { x: A.x - B.x, y: 0 }
      const dot = v1.x * v2.x + v1.y * v2.y
      const norm1 = Math.hypot(v1.x, v1.y)
      const norm2 = Math.hypot(v2.x, v2.y)

      // Compute the angle and the y-difference between the boundary coordinate and the point B
      const theta = Math.acos(dot / (norm1 * norm2))
      const y_1 = B.x * Math.tan(theta)
      
      // Finalize the boundary coordinates
      const boundary_x = direction === 'left' ? 0 : this.renderAreaDimensions.width
      const boundary_y = B.y + (A.y > B.y ? y_1 : -y_1)
      return { x: boundary_x, y: boundary_y }
    },
    handleGraphMouseOver (event) {
      if (!this.$refs.renderArea || !this.graphDataPointMarkers.length || !this.xPositions?.length || !this.graphDataPointMarkers?.length) {
        if (this.hoveredDataPoint) this.hoveredDataPoint = null
        return
      }
      const { clientX, clientY } = event
      const renderAreaRect = this.$refs.renderArea.getBoundingClientRect()
      const mouseX = clientX - renderAreaRect.left
      const mouseY = clientY - renderAreaRect.top
      
      // Find the closest xPosition to the mouseX
      const xIndex = findClosestIndex(mouseX, this.xPositions)
      // Find the index of the closest data point marker to the mouseY
      const dataPointIndex = findClosestIndex(mouseY, this.graphDataPointMarkers?.[xIndex]?.dataPoints?.map(point => point.yPos))
      if (dataPointIndex === null || xIndex === null) return
      const nearestDataPoint = this.graphDataPointMarkers?.[xIndex]?.dataPoints?.[dataPointIndex]
      
      // Ensure the distance to the nearest data point is within the threshold
      if (Math.abs(mouseY - nearestDataPoint.yPos) <= DATA_POINT_HOVER_Y_THRESHOLD) {
        if (this.hoveredDataPoint?.seriesId !== nearestDataPoint.seriesId || this.hoveredDataPoint?.datePosition !== xIndex) {
          this.hoveredDataPoint = {
            ...nearestDataPoint,
            xPos: this.graphDataPointMarkers[xIndex].xPos,
            datePosition: xIndex
          }
        }
      } else {
        this.hoveredDataPoint = null
      }
    },
    applyMarkerOffsets (offsetsInfo, seriesId) {
      for (const offsetInfo of offsetsInfo) {
        const { dateIndex, offset } = offsetInfo
        const dataPointMarker = this.graphDataPointMarkers?.[dateIndex]?.dataPoints?.find(point => point?.seriesId === seriesId)
        if (dataPointMarker) dataPointMarker.yPos += offset
      }
    },
    updateTooltipData: _.debounce(function (hoveredDataPoint) {
      if (!hoveredDataPoint) {
        this.tooltipData = null
        return
      }
      // Find the complete data
      const { seriesId, datePosition, xPos, yPos } = hoveredDataPoint
      const rawDateStr = this.rawDateSegments[datePosition]
      const dataGroup = this.graphData.find(dataGroup => dataGroup.group_by === seriesId || dataGroup.ad_id === seriesId)
      const dataIndex = dataGroup.data.findIndex(dataItem => dataItem.event_date === rawDateStr)
      const dataItem = dataGroup.data[dataIndex]

      // Get the interval start and end dates
      const startDate = convertDateString(dataItem.interval_start)
      const endDate = dataItem.interval_start !== dataItem.interval_end ? convertDateString(dataItem.interval_end) : null

      const formatDate = (date) => {
        const getOrdinal = (day) => {
          if (day > 10 && day < 14) return 'th';
          switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
          }
        }
        const day = date.getDate()
        const month = date.toLocaleString('default', { month: 'short' })
        let dateStr = `${month} ${day}${getOrdinal(day)}`
        if (date.getFullYear() !== new Date().getFullYear()) dateStr += `, ${date.getFullYear()}`
        return dateStr
      }

      // Find and format the primary metric value
      const primaryMetricType = this.getMetricLookup?.[this.selectedKpis[0]]?.type
      const primaryMetricValue = dataItem[this.selectedKpis[0]]
      const formattedPrimaryValue = formatLabelString(primaryMetricValue, primaryMetricType)
      // Compute its change from the previous date (if available)
      let formattedPrimaryValueChange = null; let primaryChangeColor = null;
      if (dataIndex > 0 || dataGroup?.boundaries?.prev) {
        const previousPrimaryValue = dataIndex > 0 ? dataGroup.data[dataIndex - 1][this.selectedKpis[0]] : dataGroup.boundaries.prev[this.selectedKpis[0]]
        const primaryValueChange = primaryMetricValue - previousPrimaryValue
        formattedPrimaryValueChange = `${primaryValueChange >= 0 ? '+' : ''}${formatLabelString(primaryValueChange, primaryMetricType)}`
        primaryChangeColor = primaryValueChange >= 0 ? 'secondary-green-50' : 'secondary-red-50'
      }
      
      // Find and format the secondary metric value
      let formattedSecondaryValue = null; let formattedSecondaryValueChange = null; let secondaryChangeColor = null;
      if (this.selectedKpis.length > 1) {
        const secondaryMetricType = this.getMetricLookup?.[this.selectedKpis[1]]?.type
        formattedSecondaryValue = formatLabelString(dataItem[this.selectedKpis[1]], secondaryMetricType)
        // Compute its change from the previous date (if available)
        if (dataIndex > 0 || dataGroup?.boundaries?.prev) {
          const previousSecondaryValue = dataIndex > 0 ? dataGroup.data[dataIndex - 1][this.selectedKpis[1]] : dataGroup.boundaries.prev[this.selectedKpis[1]]
          const secondaryValueChange = dataItem[this.selectedKpis[1]] - previousSecondaryValue
          formattedSecondaryValueChange = `${secondaryValueChange >= 0 ? '+' : ''}${formatLabelString(secondaryValueChange, secondaryMetricType)}`
          secondaryChangeColor = secondaryValueChange >= 0 ? 'secondary-green-50' : 'secondary-red-50'
        }
      }

      // Compute the tooltip transform offset
      let renderPosition = 'right'
      if (datePosition > 1 && datePosition < this.dateSegments.length - 2) renderPosition = 'center'
      else if (datePosition >= this.dateSegments.length - 2) renderPosition = 'left'
      const xGap = -12
      const xTransform = renderPosition === 'right' ? xPos + xGap : renderPosition === 'center' ? xPos - 120 : xPos - 240 - xGap
      const yTransform = yPos - 12
      
      this.tooltipData = {
        name: seriesId, // TODO: When we add support for ad-level, we'll need to display the ad name here in that case (need from API)
        date: `${formatDate(startDate)}${endDate ? ` - ${formatDate(endDate)}` : ''}`,
        primaryValue: formattedPrimaryValue,
        secondaryValue: formattedSecondaryValue,
        primaryValueChange: formattedPrimaryValueChange,
        secondaryValueChange: formattedSecondaryValueChange,
        primaryChangeColor,
        secondaryChangeColor,
        xTransform,
        yTransform
      }
    }, 50),
    // computeTooltipWidth (primaryName, primaryValue, primaryChange, secondaryName = null, secondaryValue = null, secondaryChange = null) {
    //   // TODO: Compute the width of the primary metric info row

    // }
  }
}

const findClosestIndex = (toFind, toSearch) => {
  if (!toSearch?.length || toSearch.includes(undefined)) return null
  let low = 0;
  let high = toSearch.length - 1;
  if (toFind <= toSearch[low]) return low;
  if (toFind >= toSearch[high]) return high;

  // Binary search (logn) the sorted array to narrow down the range.
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    if (toSearch[mid] === toFind) return mid;
    else if (toSearch[mid] < toFind) low = mid + 1;
    else high = mid - 1;
  }

  return (toSearch[low] - toFind) < (toFind - toSearch[high]) ? low : high;
}

const convertDateString = (dateStr) => {
  const [year, month, day] = dateStr.split('-').map(Number)
  return new Date(year, month - 1, day)
}
</script>

<style scoped>
.dynamic-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dynamic-line.primary {
  transition: opacity 150ms ease-in-out;
  opacity: 1;
}
.dynamic-line.primary.inactive {
  opacity: 0.2;
}
.data-point-marker {
  position: absolute;
  left: 0.25px;
  transform: translate(-50%, -50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.hover-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  background-color: rgba(6, 7, 16, 0.88);
  /* backdrop-filter: blur(1px); */
  z-index: 40001;

  transition: transform 200ms ease-in-out;
  transform-origin: top left;
}
.group-tooltip-name {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 150ms ease-in-out;
}
.fade-enter-from, .fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.line-enter-active {
  animation: fade-in 150ms ease-in-out forwards;
}
.line-leave-active {
  animation: fade-out 150ms ease-in-out forwards;
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}
</style>